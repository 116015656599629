header {
    transition: background-color .4s;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;
    

    .container {
        max-width: 1500px;
        position: relative;
    }
    .header-top {
        border-top: solid $light-green .53rem;
        .container {
            justify-content: space-between;
            display: flex;
        }
    }
    .col {
        flex: 1 1 33%;
        &:nth-child(1){
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1 40%;
            form {
                width: 150px;
                color: #AEB0B3;
                .search {
                    border-bottom: 2px solid #AEB0B3;
                    display: flex;
                    align-items: center;
                    input {
                        max-width: 90%;
                        border: none;
                        background-color: transparent;
                        color: inherit;
                        font-size: 15px;
                        padding: .5em 1em 0.2em 1em;
                        flex: 1 1 0;
                    }
                    button {
                        font-size: 25px;
                        background-color: transparent;
                        border: none;
                        flex: 0 0 auto;
                        padding: 0;
                        color: $blue;
                        &:hover {
                            color: $orange;
                        }
                    }
                }
            }
        }
    }
    .logo {
        padding-top: .26rem;
        padding-bottom: 1rem;
        max-width: 100%;
    }
    .top-links {
        margin-right: 2rem;
        & > * {
            display: block;
        }
        a {
            font-family: $oswald;
            color: $green;
            @include fluid-font(24px);
            margin-bottom: .5rem;
            &:hover {
                color: $violet;
            }
            span {
                font-weight: 700;
            }
            &:before {
                padding-right: .5rem;
            }
        }
        .address {
            @include fluid-font(18px);
            color: #707070;
            padding-left: 1.5rem;
        }
    }
    .header-buttons {
        display: flex;
        position: relative;
        justify-content: flex-end;
        margin-bottom: 1rem;
        a {
            white-space: nowrap; 
            padding: 2px 1.5em .6em 1.5em;
            @include letter-spacing(100);
            display: flex;
            align-items: center;
            border-bottom-left-radius: 1.5rem;
            border-bottom-right-radius: 1.5rem;
            &.icon-calendar1 {
                background-color: $green;
                &:hover {
                    background-color: $blue;
                }
            }
            &.icon-heart {
                margin-left: 9px;

            }
            &:before {
                font-size: 1.3em;
                padding-right: .5em;
            }
        }
    }
    .soc-icons {
        @extend %share-style2;
        display: inline-flex;
        .icon {
            width: 1.42rem;
            height: 1.42rem;
            font-size: 1rem;
            margin: 0 3px;
        }
    }
    .language-select {
        display: inline-flex;
        align-items: center;
        margin-left: 3rem;
        font-family: $montserrat;
        @include fluid-font(20px);
        .separator {
            padding: 0 5px;
        }
        a {
            &.active,&:hover {
                color: $blue;
            }
        }
    }
    .social-language {
        display: flex;
        justify-content: flex-end;
    }
    .header-bottom {
        background-color: #383838;
        nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            a {
                position: relative;
                text-transform: uppercase;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 auto;
                @include transition;
                line-height: 26px;
                background-color: transparent;
                padding: .4em .7em;
                font-family: $oswald;
                font-weight: 300;
                @include letter-spacing(100);
                &.nav-item-active, &:hover {
                    color: $blue;
                }
                &.pay-bill {
                    background-color: $orange;
                }
            }
        }
        @include bup(md){
            @include fluid-font(29px);
        }
        @include bdown(md) {
            font-size: 16px;
            .container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                position: static;
                padding-left: 0;
                padding-right: 0;
            }
            .nav-container {
                display: none;
            }
            nav {
                flex-direction: column;
                padding-left: 0;
                padding-right: 0;
                width: 100%;
                // height: 100vh;
                justify-content: space-evenly;
                background-color: $violet;
                // background-image: linear-gradient(to right, $violet, $blue 80%, $blue);
                // background-image: linear-gradient(to right, rgba(0, 140, 153, .95), rgba(62, 76, 202, .95));
                a {
                    display: block;
                    font-size: 16px;
                    padding: .5em 5%;
                    &.nav-item-active, &:hover {
                        background-color: $blue;
                        color: #fff;
                        &:after {
                            content: none;
                        }
                    }
                    &.nav-item {
                        &:last-of-type {
                            margin-left: 0;
                        }
                        &:before {
                            content: none;
                        }
                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }
    }
    span.nav-toggle {
        text-align: center;
        font-family: 'icomoon';
        font-size: 2em;
        min-width: 25px;
        display: block;
        transition: transform .3s ease;
        transform-origin: 50% 50%;
        color: $blue;
        &:before {
            content: "\e923";
        }
        &.close {
            transform: rotate(-180deg);
        }
        &.close:before {
            content: "\e922";
        }
    }
    @include bup(md) {
        .col {
            max-width: 33%;
        }
    }
    @include bbetween(md, nm){
        .header-top {
            .col {
                &:nth-child(1){
                    flex-direction: column;
                    padding: 15px;
                    form {
                        width: auto;
                    }
                }
                &:nth-child(2){
                    display: flex;
                    align-items: center;
                }
                &:nth-child(3){
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-bottom: 1rem;
                }
            }
        }
        .top-links {
            margin-right: 0;
        }
    }
    @include bdown(md) {
        .container {
            justify-content: center;
            flex-direction: column;
        }
        .col {
            &:nth-child(1){
                order: 2;
                display: flex;
                justify-content: space-between;
                form {
                    width: auto;
                    max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    input {
                        max-width: 100%;
                    }
                }
            }
            &:nth-child(2){
                text-align: center;
                padding-bottom: 15px;
                border-bottom: 2px solid $blue;
                order: 1;
            }
            &:nth-child(3){
                order: 3;
                display: flex;
            }
        }
        .header-buttons {
            justify-content: space-between;
            position: static;
            width: 100%;
            a {
                padding: 0;
                &.icon-calendar,
                &.icon-portal {
                    margin: 0;
                    padding: 0;
                    background-color: transparent;
                    border: none;
                    color: $blue;
                    &:hover {
                        background-color: transparent;
                    }
                }
                &.icon-heart {
                    margin-left: 0;
                }

                &:before{
                    padding: .1em;
                    font-size: 2em;
                }
                &.search-toggle:before {
                    font-size: 1.8em;
                }
                &:hover {
                    background-color: transparent;
                }
            }
        }
        .social-language {
            justify-content: space-between;
            padding-left: 5%;
            padding-right: 5%;
            margin-top: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-top: 2px solid $blue;
            .language-select {
                margin-left: 0;
            }
            .soc-icons {
                align-items: center;
                .icon {
                    width: 2rem;
                    height: 2rem;
                    &:before {
                        font-family: $icomoon;
                        font-size: 1rem;
                    }
                }
            }
        }
        .header-buttons {
            a.icon-calendar1 {
                background-color: transparent;
                color: $green;
            }
            a.icon-heart {
                background-color: transparent;
                color: $blue;
            }
        }
        .top-links {
            margin: 0;
            a {
                margin-bottom: 0;
                font-size: 2rem;
            }
            span {
                display: none;
            }
        }
        .search-container {
            flex: 1 1 auto;
            form {
                margin-right: 0 !important;
            }
        }
    }
    @include bdown(xxs) {
        .social-language {
            flex-direction: column;
            .soc-icons {
                margin-bottom: 1rem;
            }
            .language-select {
                a {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
    
}
#logout-form {
    display: none;
}
.main-slider-loading {
    header {
        background-color: rgba(0, 0, 0, .8);
    }
}
.nav-opened header {
    height: 100%;
    overflow: scroll;
}
.top-wrapper {
    overflow-x: hidden !important;
}