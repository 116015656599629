.login-popup {
    display: none;
}
.login-popup-lightbox {
    .login-popup {
        display: block;
        color: #fff;
        font-family: $montserrat;
        &--top {
            @include letter-spacing(100);
            @include fluid-font(25px);
            background-color: $green;
            font-weight: 700;
            padding: .5rem 3rem .5rem 1rem;
            position: relative;
            .icon-close {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                padding-left: .8rem;
                padding-right: .8rem;
                display: flex;
                align-items: center;
                background-color: #fff;
                color: $violet;
                &:before {
                    padding-right: 0;
                }
            }
        }
        &--bottom {
            background-color: $violet;
            padding: 1rem;
            @include bup(md){
                padding-left: 10%;
                padding-right: 10%;
            }
        }
    }
    .featherlight-content {
        background: transparent;
        border: transparent;
        @include bup(md){
            width: 724px;
        }
        @include bdown(md){
            width: 70%;
            min-width: 300px;
        }
    }
}
.bottom-section {
    @include bup(md){
        padding-bottom: 10rem;
    }
}
.sign-up {
    color: #fff;
    background-image: url(../../images/icons/envelope.svg);
    background-repeat: no-repeat;
    background-position: 100% 107%;
    background-size: auto;
    .row {
        align-items: center;
        h3 {
            text-align: left;
            color: inherit;
            font-family: $open-sans;
            @include fluid-font(36px);
            line-height: 1;
            & + p {
                @include font-count(12, 14);
                letter-spacing: .2em;
                color: rgba(#fff, $alpha: .5);
            }
        }
    }
    form {
        display: flex;
        align-content: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .input-container {
        position: relative;
        border-radius: 5px;
        margin-right: .5em;
        background-color: #fff;
        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1em;
            color: $orange;
            font-size: 1.3em;
        }
    }
    input {
        background: transparent;
        border: none;
        flex: 1 1 0;
        padding: 0 4em 0 1em;
        height: 100%;
        width: 100%;
        @include font-count(13, 18);

    }
    button {
        border: none;
        @include transition;
        white-space: nowrap;
        background-color: $green;
    }
    .btn {
        justify-content: center;
        &:hover {
            background-color: $orange !important;
        }
    }
    @include bup(lg){
        .input-container {
            min-width: 70%;
        }
    }
    @include bup(md) {
        .btn {
            min-width: 330px;
        }
        .col:nth-child(1){
            padding-left: 7%;
        }
        .input-container {
            min-width: 60%;
            margin-right: 2em;
        }
        background-size: auto 90%;
        background-position: 88% 50%;
    }
    @include bdown(md) {
        .row h3, .row h3 + p {
            text-align: center;
        }
        .btn {
            min-width: 200px;
        }
    }
}
section.tcc {
    .row {
        &.img-left {
            .tc-img {
                order: 1;
            }
            .tc-text-outer {
                order: 2;
                background-position: 90% center;
            }
            & > .col:nth-child(1) {
                @extend %block-shadow-2;
            }
        }
        &.img-right {
            .tc-img {
                order: 2;
            }
            .tc-text-outer {
                order: 1;
                background-position: 10% center;
            }
            & > .col:nth-child(2) {
                @extend %block-shadow-2;
            }
        }
    }
    .col {
        padding-left: 0;
        padding-right: 0;
        flex: 1 1 50%;
    }
    .tc-img {
        position: relative;
        img {
            display: block;
        }
    }
    .tc-list {
        .col {
            display: flex;
            align-items: center;
            @include fluid-font(29px);
            font-weight: 700;
            padding: 0 1rem;
            padding-top: 1rem;
            a {
                display: flex;
                color: #fff;
                align-items: center;
                &:hover {
                    filter: brightness(.8);
                }
            }
            img {
                margin-right: 1rem;
            }
            @include bup(md){
                &:first-of-type {
                    border-right: 1px solid rgba(#D1D1D1, $alpha: .37);
                }
            }
            @include bdown(md){
                img {
                    max-width: 20%;
                }
            }
        }
    }
    .tc-text {
        color: #fff;
        padding: 4em 9% 3em;
        @include font-count(14, 18);
        margin-bottom: 0;
        h2 {
            @include fluid-font(57px);
            text-align: left;
            margin-bottom: .1em;
            font-weight: 500;
            color: #fff;
        }
        h5.subtitle {
            color: #fff;
            text-align: left;
            font-family: $montserrat;
            @include fluid-font(22px);
            @include letter-spacing(200);
            margin-bottom: 1.5em;
            @include rlh(26px);
        }
        p {
            line-height: 28px;
            &:last-of-type {
                margin-bottom: 2em;
            }
        }
        .icon {
            color: $orange;
            margin-left: 1em;
        }
        .btn {
            @include fluid-font(18px);
            font-weight: 400;
            font-family: $montserrat;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
    img.img-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        @include bdown(md){
            position: static;
        }
    }
}
section.our-news {
    .our-news-card {
        display: block !important;
        font-family: $open-sans;
        overflow: hidden;
        &-inner {
            background-color: #fff;
        }
        &-title {
            @include fluid-font(28px);
            font-weight: 700;
            color: $green;
            margin: .5rem 0;
            border-top: 1px solid $green;
            border-bottom: 1px solid $green;
            padding: .5rem 0;
            box-sizing: content-box;
            display: flex;
            align-items: center;
            @include transition;
            @include bup(md){
                min-height: 70px;
            }
        }
        &-excerpt {
            color: #5F5F5F;
            margin-bottom: .7rem;
            @include fluid-font(16px);
            @include rlh(25px);
        }
        &-date {
            color: #3e3e3e;
            @include transition;
            @include fluid-font(20px);
            @include letter-spacing(100);
            padding: .5rem 0;
            &:before {
                color: $violet;
                content: "\e907";
                display: inline-block;
                padding-right: 1em;
                font-family: $icomoon;
            }
        }
        &-media-wrapper {
            position: relative;
            height: 0;
            padding-bottom: (9 * 100% / 16);
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &-rm {
            text-transform: uppercase;
            color: $blue;
            font-weight: 600;
            &:after {
                font-family: icomoon;
                display: inline-block;
                content: "\e90a";
                opacity: 0;
                padding-left: 10px;
                transform: translateX(-10px);
                @include transition;
            }
        }
        &:hover {
            .our-news-card-rm:after {
                opacity: 1;
                transform: translateX(0);
            }
            .our-news-card-title {
                color: $blue;
                border-top-color: $blue;
                border-bottom-color: $blue;
            }
        }
    }
}
section.benefits-summary {
    .benefits-button-container {
        display: inline-flex;
        position: relative;
        padding-bottom: 1em;
        a {
            display: inline-flex;
            position: relative;
            align-items: center;
            background-color: #fff;
            border: 2px solid $green;
            padding: .5em 2.8em .5em 2em;
            color: #1D235B;
            padding-right: 4em;
            span {
                text-align: left;
                padding-left: 1em;
                @include font-count(18, 25);
                @include transition;
                font-weight: 600;
            }
            &:before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                @include font-count(30, 68);
                color: $green;
                right: .5em;
            }
            &:hover {
                border-color: $blue;
                span {
                    color: $green;
                }
            }

        }
    }
}
.just-in {
    background-color: $orange;
    padding: 1rem 0;
    text-align: center;
    a {
        color: #fff !important;
        display: inline-flex;
        align-items: center;
        span {
            &:first-of-type {
                font-weight: 700;
                @include fluid-font(27px);
                padding-right: .5em;
                white-space: nowrap;
            }
        }
        &:after {
            content: "\e91e";
            font-family: $icomoon;
            padding-left: .5em;
        }
        &.empty {
            &:after {
                content: none;
            }
        }
    }
}
.icon-links {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 1rem;
    li {
        text-align: center;
        flex: 1 0 0; 
    }
    .icon-link {
        position: relative;
        display: inline-flex;
        width: 88%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        height: 100%;
        &--img-container {
            @include transition;
            flex: 0 0 30%;
        }
        &--info {
            left: 0;
            width: 100%;
            display: block;
            padding: 1rem;
            position: relative;
            h4 {
                color: $green;
                font-weight: 700;
                letter-spacing: normal;
            }
            span {
                font-family: $montserrat;
                @include fluid-font(15px);
                font-weight: 300;
            }
            &:after {
                @extend %block-shadow-1;
            }
        }
    }
    @include bup(md){
        justify-content: center;
        li {
            position: relative;
            max-width: 514px;
            &:after {
                content: "";
                display: block;
                position: absolute;
                width: 1px;
                height: 65%;
                background-color: rgba(#D1D1D1, $alpha: .37);
                right: 0;
                top: 0;
            }
            &:last-of-type {
                &:after {
                    content: none;
                }
            }
        }
    }
    @include bdown(md){
        flex-direction: column;
        li {
            margin-bottom: 2rem;
        }
        .icon-link {
            max-width: none;
            &--img-container {
                @include transition;
                flex: 0 0 20%;
            }
        }
    }
}
.health-services {
    display: flex;
    &--inner {
        text-align: center;
        font-weight: 700;
        @include font-count(18, 34);
        color: $violet;
        &:hover {
            color: $blue;
        }
    }
    a {
        display: block;
    }
    .img-container {
        @extend %block-shadow-1;
        margin: 0 auto 1rem;
        max-width: 90%;
        img {
            display: block;
        }
        & + div {
            @include rlh(30px);
        }
    }
    @include bup(md){
        flex-wrap: wrap;
        li {
            flex: 0 1 25%;
            margin-bottom: 2rem;
        }
    }
    @include bdown(md){
        flex-wrap: wrap;
        li {
            flex: 0 1 50%;
            margin-bottom: 2rem;
        }
    }
}
.blue-bg .health-services {
    &--inner {
        color: #fff;
        
    }
}
section.inner-services {
    ul {
        // display: block;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        flex-direction: row;
        // &:after {
        //     display: table;
        //     content: "";
        //     clear: both;
        // }
        li {
            // display: inline-block;
            // width: 45%;
            flex: 0 0 45%;
            display: flex;
            flex-direction: column;
            padding: 1rem 20% 1rem 3rem;
            margin-left: 2%;
            margin-right: 2%;
            border-bottom: 1px solid rgba(#707070, $alpha: .4);
            color: $light-green;
            font-family: $montserrat;
            @include fluid-font(22px);
            @include rlh(27px);
            &:nth-child(1),
            &:nth-child(2){
                border-top: 1px solid rgba(#707070, $alpha: .4);
            }
            @include bdown(md){
                flex: 0 0 96%;
                &:nth-child(2){
                    border-top: none;
                }
            }
            // &:nth-child(odd) {
            //     float: left;
            //     // margin-right: 2%;
            // }
            // &:nth-child(even) {
            //     float: right;
            //     // margin-left: 2%;
            // }
            &:before {
                color: $violet;
                top: 1rem;
                left: 1rem;
                transform: none;
            }
            a.learn-more {
                font-size: 14px;
                color: #5F5F5F;
                display: flex;
                &:hover {
                    color: $blue;
                }
                &:after {
                    content: "\e91e";
                    margin-left: .5rem;
                    color: $blue;
                    font-family: $icomoon;
                }
            }
        }
    }
}

.home-welcome {
    .col {
        &:last-of-type {
            padding: 1rem 3rem;
            background-color: rgba($color: $green, $alpha: .15);
            font-family: $oswald;
            @include fluid-font(24px);
            font-weight: 300;
            color: #676767;
            img {
                margin-left: -1.5rem;
                margin-bottom: 1rem;
            }
        }
        @include bup(md){
            &:first-of-type {
                padding-right: 5%;
                margin-right: 5%;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 2px;
                    height: 70%;
                    background-color: rgba(#D1D1D1, $alpha: .37);
                }
            }
            &:last-of-type {
                margin-top: -2rem;
            }
        }
    }
}
.team-links {
    @include bdown(md){
        &.row {
            display: flex;
        }
    }
    @include bdown(sm){
        &.row {
            display: block;
        }
    }
    a {
        display: block;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        border-top: .63rem solid $green;
        @extend %block-shadow-1;
        &:hover {
            border-top-color: $blue;
            span {
                color: $blue;
            }
        }
        &:before {
            position: absolute;
            content: "";
            z-index: 5;
            left: 0;
            width: 100%;
            top: 40%;
            bottom: 0;
            background-image: linear-gradient(to bottom, transparent, #0C3152);
        }
        span {
            display: block;
            position: absolute;
            z-index: 10;
            color: #fff;
            @include fluid-font(46px);
            font-weight: 700;
            bottom: .5rem;
            left: 0;
            width: 100%;
            text-align: center;
            @include transition;
        }
    }
}
.achievments {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    padding-top: 4rem;
    padding-bottom: 1rem;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $violet;
        z-index: 5;
    }
    li {
        flex: 0 1 50%;
        width: 50%;
        z-index: 10;
        padding-bottom: 2rem;
        &:nth-child(even){
            @include bup(md){
                align-self: flex-start;
                .achievments--line {
                    margin-left: auto;
                    &:before {
                        right: -16px;
                    }
                    &:after {
                        right: -10px;
                    }
                }
            }
            @include bdown(md){
                align-self: flex-end;
                .achievments--line {
                    &:before {
                        right: -20px;
                    }
                    &:after {
                        right: -14px;
                    }
                }
                .achievments--inner {
                    margin-left: auto;
                    .achievments--date{
                        order: 1;
                    }
                    .achievments--info{
                        order: 2;
                    }
                }
            }
        }
        &:nth-child(odd){
            align-self: flex-end;
            .achievments--line {
                &:before {
                    right: -20px;
                }
                &:after {
                    right: -14px;
                }
            }
            .achievments--inner {
                margin-left: auto;
                .achievments--date{
                    order: 1;
                }
                .achievments--info{
                    order: 2;
                }
            }
        }
    }
    &--line {
        background-color: $violet;
        height: 3px;
        position: relative;
        width: 10%;
        z-index: 10;
        &:before {
            position: absolute;
            content: "";
            width: 32px;
            height: 32px;
            background-color: transparent;
            border: 3px solid $violet;
            border-radius: 50%;
            top: -15px;
            right: -16px;
        }
        &:after {
            position: absolute;
            content: "";
            width: 20px;
            height: 20px;
            background-color: $violet;
            border-radius: 50%;
            top: -9px;
        }
    }
    &--inner {
        display: block;
        width: 90%;
        position: relative;
        top: -33px;
        @extend %block-shadow-1;
    }
    &--date {
        flex: 0 0 auto;
        font-size: 2.125rem;
        font-weight: 500;
        color: #111;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        color: #fff;
        font-family: $montserrat;
        font-size: 22px;
        span {
            display: inline-flex;
            padding: 0 30px;
            min-height: 63px;
            align-items: center;
            &:first-of-type {
                flex: 1 1 auto;
                background-color: $blue;
                justify-content: flex-end;
            }
            &:last-of-type {
                flex: 0 0 auto;
                background-color: $violet;
            }
        }
    }
    &--info {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0 2rem;
        & > * {
            flex: 0 0 50%;
        }
        .img-container {
            margin-right: 1rem;
        }
        
    }
    &--container {
        position: relative;
        margin: 2rem auto;
        &:before {
            top: 0;
        }
        &:after {
            bottom: 0;
        }
        &:after, &:before {
            content: "";
            position: absolute;
            width: 53px;
            height: 3px;
            left: 50%;
            transform: translateX(-50%);
            background-color: $violet;
        }
        @include bdown(md){
            &:after, &:before {
                left: 0;
                transform: none;
            }
        }
    }
    @include bup(md){
       &:after {
            left: calc(50% - 1px);
        }
    }
    @include bdown(md){
        li {
            width: auto;
        }
        .achievments--info {
            flex-direction: column;
            & > * {
                flex: 1 1 100%;
            }
        }
    }
}
.list-switch {
    display: flex;
    justify-content: space-between;
    &--item {
        color: $light-green;
        border: 1px solid $light-green;
        @include fluid-font(28px);
        display: inline-flex;
        align-items: center;
        padding: 1rem 3rem;
        max-width: 489px;
        position: relative;
        &.active {
            color: #fff;
            background-color: $green;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 21px 0 21px;
                border-color: #568E14 transparent transparent transparent;
                top: 100%;
                right: 3rem;
            }
        }
        &:hover {
            color: #fff;
            background-color: $green;
        }
        i {
            @include font-count(34, 80);
            // @include fluid-font(80px);
            margin-right: .5rem;
        }
        span {
            font-weight: 700;
        }
    }
    @include bdown(md){
        flex-direction: column;
        align-items: center;
        &--item {
            margin-bottom: 1.5rem;
            padding: .5rem 1.5rem;
            justify-content: center;
            width: 80%;
            &.active {
                order: 2;
            }
        }
    }
}
.program-event {
    background-color: rgba($color: $light-green, $alpha: .07);
    h5 {
        color: $violet;
        @include fluid-font(36px);
        font-family: $montserrat;
        font-weight: 700;
    }
    &--item {
        padding: 2rem 0;
        margin-bottom: 2rem;
        display: flex;
    }
    &--image-container {
        flex: 0 1 50%;
        margin-right: 2rem;
    }
    &--info {
        flex: 1 1 auto;
        position: relative;
    }
    &--date {
        @include fluid-font(22px);
        color: $green;
        margin-bottom: 1rem;
    }
    &--title{
        font-family: $open-sans;
        color: $blue;
        @include fluid-font(25px);
        font-weight: 700;
        margin-bottom: 1rem;
    }
    &--text {

    }
    &--address-container {
        display: block;
        text-align: right;
        div {
            display: inline-flex;
            max-width: 257px;
            color: $violet;
            text-align: left;
            &:before {
                content: "\e905";
                font-family: $icomoon;
                display: block;
                color: $blue;
                margin-right: .5rem;
            }
        }
    }
    &--more-address-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: .5rem;
        // text-align: right;
        div {
            display: inline-flex;
            max-width: 257px;
            color: $violet;
            text-align: left;
            &:before {
                content: "\e905";
                font-family: $icomoon;
                display: block;
                color: $blue;
                margin-right: .5rem;
            }
        }
    }
    &--type {
        // position: absolute;
        // top: 0;
        // right: 0;
        position: relative;
        display: inline-flex;
        align-items: center;
        color: $blue;
        top: -.8rem;
        // margin-bottom: 1rem;
        &:before {
            border-radius: 50%;
            width: 1em;
            height: 1em;
            display: inline-flex;
            margin-right: .5em;
            background-color: $violet;
            content: "";
        }
    }
    @include bup(md){
        &--image-container {
            flex: 0 0 320px;
        }
    }
    @include bdown(md){
        &--address-container {
            text-align: left;
            margin-bottom: 1rem;
            div {
                max-width: none;
            }
        }
        &--more-address-container {
            flex-direction: column;
            div {
                margin-top: .5rem;
                max-width: none;
            }
        }
    }
    @include bdown(xs){
        &--item {
            flex-direction: column;
        }
        &--image-container {
            margin-bottom: 1rem;
        }
    }
}
.apply {
    color: #fff;
    @include fluid-font(25px);
    font-weight: 600;
    .col:first-child {
        @include letter-spacing(100);
    }
    .btn {
        &:hover {
            background-color: $violet;
        }
    }
    @include bup(md){
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .small-text {
        @include fluid-font(21px);
        @include letter-spacing(100);
    }
}
.hor-block {
    border-bottom: 9px solid;
    &.row {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.79rem;
    }
    .col {
        padding: 0;
    }
    &--media-wrapper {
        width: 100%;
        position: relative;
        // height: 0;
        height: 100%;
        padding-bottom: 56.25%;
        img, iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        img {
            object-fit: cover;
        }
    }
    &--date {
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        margin-bottom: 1rem;
        @include fluid-font(15px);
        @include letter-spacing(100);
        color: $violet;
        &:before {
            content: "\e907";
            margin-right: 1em;
            font-family: $icomoon;
            font-weight: 400;
        }
    }
    &--title {
        padding: .5rem .5em;
        margin: 0 -3rem .8rem -1rem;
        @include fluid-font(31px);
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
        position: relative;
        z-index: 5;
    }
    &--inner {
        height: 100%;
        padding: 1rem 2rem; 
    }
    &:nth-child(odd){
        border-color: $violet;
        .hor-block--inner {
            transition: background-color .5s ease-in-out;
            background-color: rgba($violet, $alpha: .06);
        }
        .hor-block--title,
        .hor-block--name {
            background-color: $violet;
        }
        .hor-block--date {
            color: $violet;
        }
        &:hover {
            .hor-block--inner {
                transition: background-color .5s ease-in-out;
                background-color: rgba($violet, $alpha: .3);
            }
        }
        .soc-share-container {
            .soc-share {
                a {
                    color: $violet;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }
    &:nth-child(even){
        border-color: $green;
        .hor-block--inner {
            transition: background-color .5s ease-in-out;
            background-color: rgba($green, $alpha: .06);
        }
        .hor-block--title,
        .hor-block--name {
            background-color: $green;
        }
        &:hover {
            .hor-block--inner {
                background-color: rgba($green, $alpha: .3);
            }
        }
        .hor-block--date {
            color: $green;
        }
        .soc-share-container {
            .soc-share {
                a {
                    color: $green;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }

    &.team {
        &:nth-child(odd){
            background-color: rgba($violet, $alpha: .06);
        }
        &:nth-child(even){
            background-color: rgba($green, $alpha: .06);
        }
        &:hover {
            .hor-block--inner {
                background-color: transparent !important;
            }
        }
        .hor-block--inner {
            position: relative;
            padding-bottom: 3rem;
        }
        .hor-block--title {
            margin: 0 -1rem .8rem -3rem;
        }
        .hor-block--media-wrapper {
            width: 100%;
            padding-bottom: 0;
            position: relative;
            img {
                position: static;
            }
        }
        .hor-block--name {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 20;
            text-align: center;
            color: #fff;
            font-family: $montserrat;
            font-weight: 700;
            @include fluid-font(24px);
            padding-top: 7px;
        }
        .hor-block--email {
            position: absolute;
            bottom: 1rem;
            left: 2rem;
            font-family: $oswald;
            color: #444;
            a {
                color: $blue;
            }
        }
        @include bbetween(sm, md){
            .row {
                display: flex;
            }
        }
        @include bdown(sm){
            margin-bottom: 2rem;
            .row {
                margin-left: 0;
                margin-right: 0;
            }
            .hor-block--inner {
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;
            }
            .hor-block--title {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &.loans {
        &:nth-child(odd){
            background-color: rgba($violet, $alpha: .06);
            .hor-block--title {
                margin-left: -3rem;
                margin-right: 0;
            }
        }
        &:nth-child(even){
            background-color: rgba($green, $alpha: .06);
            .row {
                flex-direction: row-reverse;
            }
        }
        .hor-block--inner {
            background-color: transparent !important;
        }
        &:hover {
            .hor-block--inner {
                background-color: transparent !important;
            }
        }
    }

    .soc-share-container {
        justify-content: flex-end;
        @extend %share-style1;
        .soc-share {
            a {
                font-size: 1rem;
                height: 1rem;
                width: 1rem;
                padding: 0 1rem;
            }
        }
    }

    @include bdown(md){
        &.row {
            padding-bottom: 0;
        }
    }
}
.num-container {
    .col {
        &:nth-child(odd){
            .inner {
                background-color: $green;
            }
        }
        &:nth-child(even){
            .inner {
                background-color: $violet;
            }
        }
    }
    .inner {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .num {
        @include fluid-font(64px);
        font-weight: 700;
        color: #444;
        font-family: $montserrat;
    }
    .num-info {
        color: #fff;
        @include fluid-font(21px);
        font-weight: 700;
    }
    @include bdown(md){
        flex-wrap: wrap;
        .col {
            flex: 0 0 50%;
        }
        .inner {
            flex-direction: column;
            padding: 5px;
        }
    }
    @include bdown(xxs){
        .col {
            flex: 0 0 100%;
        }
    }
}
.regular-hours {
    background-color: #eee;
    h4 {
        color: $blue;
    }
}
.about-us-top {
    margin-top: 1rem;
    p:nth-of-type(1){
        font-weight: 700;
        font-family: $montserrat;
        @include fluid-font(24px);
    }
    p:nth-of-type(3),
    p:nth-of-type(4)
    {
        @include fluid-font(22px);
        font-weight: 700;
        font-family: $montserrat;
    }
}
.person-links {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
        flex: 0 0 23%;
        height: 424px;
        // margin-bottom: 3%;
        margin: 0 1% 3%;
        @extend %double-shadow;
        a {
            width: 100%;
            height: 100%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            @include fluid-font(17px);
            font-weight: 700;
            position: relative;
            border-top: 6px solid $light-green;
            display: block;
            overflow: hidden;
            z-index: 5;
            &:hover {
                border-top-color: $blue;
                color: #fff;
                .info {
                    top: 0;
                }
            }
            .default {
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                position: absolute;
                background-color: #000;
                padding-top: 20%;
                padding: 20% 5% 20% 5%;
                display: block;
                text-align: center;
                z-index: -1;
                img {
                    max-width: 92px;
                    position: static;
                    width: auto;
                    height: auto;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
                span {
                    @include fluid-font(25px);
                    font-weight: 700;
                }
            }
            .info {
                content: "";
                left: 0;
                width: 100%;
                top: 100%;
                height: 100%;
                background-color: rgba(#000, $alpha: .81);
                z-index: 5;
                position: absolute;
                transition: all .4s ease-in-out;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 1rem;
                span {
                    &:nth-of-type(1){
                        @include fluid-font(25px);
                        font-weight: 700;
                    }
                    &:nth-of-type(2){
                        @include fluid-font(16px);
                    }
                    &:nth-of-type(3){
                        font-style: italic;
                    }
                }
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    @include bdown(md){
        li {
            flex: 0 0 48%;
            // margin-bottom: 20px;
            margin: 0 1% 20px;
        }
    }
    @include bdown(xxs){
        li {
            flex: 0 0 100%;
            margin: 0 0 20px;
            a {
                min-height: 50px;
            }
        }
    }
}
.person-subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    span {
        color: $orange;
        @include fluid-font(41px);
        font-weight: 700;
        flex: 0 0 auto;
    }
    &:after {
        display: block;
        margin-top: 10px;
        margin-left: 10px;
        content: "";
        flex: 1 1 auto;
        height: 7px;
        background-color: $light-green;
    }
}
.faq {
    .acc-tab {
        background-color: rgba(#15BEF0, $alpha: .1);
        border: 2px solid rgba(#F7EFDA, $alpha: .07);
        margin-bottom: 1em;
        cursor: pointer;
        .acc-text {
            @include font-count(13, 18);
            padding-top: 1em;
            @include bup(md){
                line-height: 29px;
            }
        }
        .acc-title {
            font-family: $montserrat;
            color: $blue;
            @include fluid-font(32px);
            @include transition;
            font-weight: 700;
            padding-left: 28px;
            padding-right: 6rem;
            min-height: 4.84rem;
            display: flex;
            align-items: center;
            position: relative;
            &:after {
                @include transition;
                content: "+";
                display: flex;
                position: absolute;
                font-size: 62px;
                width: 4.84rem;
                height: 4.84rem;
                color: #fff;
                background-color: $green;
                right: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                // height: 100%;
                cursor: pointer;
                @include bdown(xs) {
                    font-size: 40px;
                    width: 50px;
                }
            }
            &:hover:after {
                background-color: $blue;
            }
            &.accordion-active {
                &:after {
                    background-color: $violet;
                    content: "-";
                    max-height: 4.84rem;
                }
            }
        }
        .acc-text {
            padding-left: 28px;
            padding-right: 4.84rem;
            padding-bottom: 1em;
            @include fluid-font(22px);
            color: #444;
            font-weight: 600;
        }
        &.tab-active {
            background-color: rgba($green, $alpha: .04);
        }
    }
}
.rate-notes {
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include bup(md){
        .col:first-child {
            border-right: 1px solid $green;
        }
    }
    &--info {
        color: $blue;
        font-weight: 700;
        font-family: $montserrat;
        @include fluid-font(27px);
        position: relative;
        padding-left: 2rem;
        &:before {
            content: "\e93b";
            display: inline-flex;
            position: absolute;
            font-family: $icomoon;
            color: $green;
            top: 0;
            left:0;
        }

    }
}
.tile {
    &--list,
    &--header {
        display: flex;
        font-family: $montserrat;
        li {
            flex: 1 1 0;
            display: flex;
            justify-content: center;
        }
    }
    &--header {
        @include fluid-font(25px);
        font-weight: 500;
        color: #777;
    }
    &--list {
        @include fluid-font(32px);
        font-weight: 700;
        color: $blue;
        li {
            border: 1px solid #FEFDF8;
            padding: 1.25rem;
        }
        &:nth-child(odd){
            li {
                background-color: rgba($green, $alpha: .1);
            }
        }
        &:nth-child(even){
            li {
                background-color: rgba($green, $alpha: .15);
            }
        }
    }
}
.card-link {
    background-color: $blue;
    color: #fff;
    padding: 1.5rem;
    .btn {
        display: inline-flex;
        align-items: center;
        height: 2.42rem;
        &:hover {
            background-color: $violet;
        }
        &:before {
            font-size: 1.7em;
        }
    }
    &--info {
        display: flex;
        align-items: center;
        font-family: $montserrat;
        @include fluid-font(25px);
        font-weight: 700;
        text-transform: uppercase;
        @include letter-spacing(70);
        margin-bottom: 1rem;
        img {
            margin-right: .5rem;
        }
    }
}

section.planning-outline {
    h3 {
        color: $blue;
        @include fluid-font(34px);
    }
    .row {
        justify-content: space-between;
    }
    .btn {
        @include fluid-font(24px);
        border-radius: 0;
        @include bup(md){
            width: 420px;
            &:before {
                padding-right: 2rem;
            }
        }
    }
}
.contact-info {
    padding-top: 1rem;
    padding-left: 1rem;
    li {
        position: relative;
        padding-left: 2rem;
        margin-bottom: 1rem;
        &:first-of-type {
            &:after {
                content: "";
                width: 30%;
                height: 1px;
                display: block;
                margin-top: 1rem;
                background-color: $light-green;
            }
        }
        @include fluid-font(22px);
        &:before {
            position: absolute;
            left: 0;
            top: 5px;
            font-size: 2rem;
            color: $green;
        }
    }
    &--title {
        color: $green;
        margin-bottom: .5rem;
    }
    &--text {
        @include letter-spacing(100);
        @include rlh(29px);
        @include fluid-font(20px);
    }
    &--address {
        color: $green;
        @include fluid-font(22px);
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        & + .detailed-location-info {
            display: none;
        }
        &.expanded {
            span.contact-info--address-info:after {
                transform: rotate(180deg);
            }
            & + .detailed-location-info {
                display: block;
            }
        }
        span {
            flex-grow: 0;
            display: inline-flex;
            align-items: center;
            &.contact-info--address-info {
                &:after {
                    background-color: $green;
                    color: white;
                    width: 2rem;
                    height: 2rem;
                    font-size: 2rem;
                    content: "\e920";
                    flex-grow: 0;
                    font-family: $icomoon;
                    margin-left: 1rem;
                    margin-right: 3px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    transform: rotate(0);
                    transition: all 0.4s linear;
                }
            }
            &.contact-info--address-line {
                height: 1px;
                flex-grow: 1;
                background-color: $green;
            }
        }
        &:before {
            flex-grow: 0;
            font-size: 2rem;
            padding-right: .7rem;
        }
    }
    a {
        display: block;
        color: $base;
        &:hover {
            color: $green;
        }
    }
}
.working-hours {
    li {
        display: flex;
        @include fluid-font(20px);
        @include rlh(29px);
        @include letter-spacing(100);
        span {
            &:first-child {
                color: $violet;
                flex: 0 1 28%;
                text-align: right;
                margin-right: 2%;
            }
            &:last-child {
                flex: 0 1 auto;
            }
        }
        &:first-of-type {
            margin-bottom: 1rem;
            color: $green;
            &:before {
                content: "";
                display: block;
                flex: 0 1 28%;
                margin-right: 2%;
            }
        }
        &:last-of-type {
            margin-top: 2rem;
            &:before {
                content: "";
                display: block;
                flex: 0 1 28%;
                margin-right: 2%;
            }
        }
    }
}

.tmh {
    background-color: rgba($color: $light-green, $alpha: .15);
    &--top {
        padding: 2rem;
        span {
            display: block;
            font-size: 15px;
            font-weight: 700;
        }
    }
    &--bottom {
        padding: 2rem;
    }
    &--list {
        li {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
    &--info {
        border-left: 1px solid $light-green;
        margin-left: 1rem;
        padding-left: 1rem;
    }
    &--img {
        flex: 0 0 50px;
    }
    h5 {
        color: $light-green;
        @include fluid-font(22px);
        font-family: $montserrat;
    }
    &--working-hours {
        &.working-hours {
            padding-left: 1rem;
            li {
                &:first-of-type {
                    position: relative;
                    padding-left: 2rem;
                    &:before {
                        font-family: $icomoon;
                        position: absolute;
                        content: "\e911";
                        left: 0;
                        top: 0;
                        font-size: 2rem;
                    }
                }
                &:last-of-type {
                    font-family: $montserrat;
                    letter-spacing: normal;
                    &:before {
                        content: none;
                    }
                }
            }
        }
    }
    &--contact-info {
        li {
            border-bottom: 1px solid $light-green;
            &:after {
                content: none!important;
            }
        }
    }
    @include bdown(md){
        &--contact-info {
            li {
                padding-bottom: 1rem;
            }
        }
        &--working-hours {
            &.working-hours {
                li {
                    padding-left: 2rem;
                    span {
                        &:first-child {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
.map-container {
    iframe {
        width: 100%;
        height: 285px;
    }
    border-right: 1px solid $light-green;
    padding-right: 15px;
}
.simple-styled-block {
    background-color: rgba($green, $alpha: .14);
    padding: 2rem;
    margin-bottom: 2rem;
    h3 {
        color: $violet;
        margin-bottom: 2rem;
    }
    .btn-container + h3 {
        margin-top: 2rem;
    }
    .link-more {
        @include fluid-font(24px);
        font-weight: 400;
        letter-spacing: normal;
    }
    @include bdown(md) {
        .btn-container {
            margin-top: 1rem;
        }
    }
}
.person-details {
    &--img {
        @extend %double-shadow;
        display: inline-block;
        img {
            border-top: solid 6px $light-green;
        }
    }
    &--info {
        padding-top: 2rem;
        margin-top: 2rem;
        border-top: #707070 1px solid;
    }
    h2 {
        color: $blue;
        @include fluid-font(33px);
        font-weight: 700;
        font-family: $open-sans;
        margin-bottom: 1rem;
    }
    h4 {
        color: #808080;
        @include fluid-font(24px);
        letter-spacing: normal;
        margin-bottom: 1rem;
    }
    h5.person-details--cat {
        font-style: italic;
        color: #535353;
        margin-bottom: 1rem;
        & + a {
            color: $light-green;
            font-size: 1.2em;
            &:hover {
                color: $blue;
            }
            &:before {
                padding-right: .5rem;
            }
        }
    }
}
section.current-openings {
    .block {
        margin-bottom: 3em;
        h4 {
            text-align: left;
            color: $violet;
            font-family: $open-sans;
            @include font-count(28, 46);
            font-weight: 700;
            padding-left: 5%;
            padding-right: 5%;
            @include letter-spacing(20);
        }
        h5 {
            text-align: left;
            font-family: $open-sans;
            font-weight: 700;
            color: $blue;
            @include font-count(20, 25);
        }
        .job-details {
            padding: 1.5em 5%;
            background-color: rgba($color: $green, $alpha: .07);
            margin-bottom: 1em;
        }
        .btn {
            background-color: $green;
            font-family: $montserrat;
            &:hover {
                background-color: $blue;
            }
        }
    }
    .current-openings--aside {
        @include bup(md){
            padding-top: 4rem;
        }
        h5 {
            font-family: $montserrat;
            color: $light-green;
        }
        li {
            border-bottom: 1px solid $light-green;
            margin-bottom: 1rem;
            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}

.feeling-stuck {
    .container {
        position: relative;
        & > * {
            display: inline-block;
            vertical-align: middle;
            
        }
        &:after {
            display: block;
            position: absolute;
            background-color: $orange;
            content:"";
            left: 0;
            right: 0;
            top: 20%;
            bottom: 20%;
            z-index: -1;
            @include bdown(md) {
                bottom: 0;
            }
        }
        div {
            margin: 0 1rem;
            font-family: $oswald;
            color: #fff;
            font-weight: 700;
            @include fluid-font(25px);
            @include letter-spacing(100);
            @include bdown(md) {
                display: block;
                margin: 1rem auto;
            }
        }
        .btn {
            background-color: #fff;
            color: $dark;
            @include bdown(md) {
                margin-bottom: 2rem;
            }
        }
    }
}
.link-more {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: tramslateY(-50%);
    display: inline-flex;
    align-items: center;
    font-family: $poppins;
    font-size: 14px;
    color: #929292;
    font-weight: 600;
    @include letter-spacing(200);
    &:after {
        font-family: $icomoon;
        content: "\e91e";
        display: inline-block;
        padding-left: .5rem;
        color: $blue;
    }
    &:hover {
        color: $violet;
    }
    @include bdown(md){
        position: static;
        transform: none;
    }
    &--green {
        &:after{
            color: $green;
        }
    }
    &--white {
        color: #fff;
        &:after{
            color: #fff;
        }
    }
    &--center {
        @include bup(md){
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &--static {
        position: static;
    }
    &--alt {
        &:before {
            font-family: $icomoon;
            content: "\e916";
            display: inline-block;
            padding-right: .5rem;
            color: $violet;
        }
        &:after {
            content: none;
        }
    }
}

.back-link {
    color: #A2A0A0;
    &:before {
        color: $blue;
    }
    &:hover {
        color: $blue;
    }
}


section.fullwidth-banner {
    .banner-layout {
        background-size: auto 100%;
        background-repeat: no-repeat;
        position: relative;
        color: #fff;
        .title-1 {
            @include fluid-font(58px);
            font-family: $oswald;
            @include letter-spacing(20);
            font-weight: 500;
            display: flex;
            margin-bottom: .5rem;
        }
        .title-2 {
            @include fluid-font(25px);
            font-family: $montserrat;
            line-height: 1;
            margin-bottom: 2rem;
            @include letter-spacing(200);
        }
        .container {
            z-index: 5;
            position: relative;
        }
        &.position-right {
            background-position: left top;
            &:after {
                position: absolute;
                content: "";
                top: 0;
                height: 100%;
                left: 0;
                width: 100%;
                background-image: linear-gradient(to left, #000, #000 35%, transparent 70%, transparent);
            }
            .container {
                text-align: right;
            }
        }
        &.position-left {
            background-position: right top;
            &:after {
                position: absolute;
                content: "";
                top: 0;
                height: 100%;
                left: 0;
                width: 100%;
                background-image: linear-gradient(to right, #000, #000 35%, transparent 70%, transparent);
            }
            .container {
                text-align: left;
            }
        }
        .btn-container {
            margin-top: 2rem;
        }
        .banner {
            &--info {
                text-align: left;
                display: inline-block;
                padding-left: 2em;
                padding-right: 2em;
                color: #fff;
                p {
                    font-family: $open-sans;
                }
            }
            &--inner {
                padding: 3rem;
                margin-bottom: 1rem;
                line-height: 1;
                display: inline-block;
            }
        }
        & + .banner-layout {
            margin-top: 1rem;
        }
        @include bup(md){
            .banner--info {
                max-width: 815px;
            }
        }
        @include bdown(md){
            .banner--info {
                max-width: 50%;
                padding-top: 30px;
                padding-bottom: 30px;
            }
        }
        @include bdown(sm){
            .banner--info {
                max-width: none !important;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &.coach-list {
        .banner-layout {
            .title-2 {
                color: $dark;
            }
        }
    }
}

.aside {
    &--title {
        margin-bottom: 1em;
    }
    &--nav {
        @include fluid-font(25px);
        font-family: $poppins;
        li {
            margin-bottom: 1em;
        }
        a {
            color: $base !important;
            &:hover, &.active {
                color: $green !important;
            }
        }
    }

    &--container {
        margin-bottom: 1.5rem;
    }
    &--banner {
        background-color: $violet;
        color: #fff;
        padding: 2rem 1rem;
        background-image: url(../../images/icons/envelope.png);
        background-repeat: no-repeat;
        background-position: 107% 107%;
        background-size: auto;
        @include fluid-font(15px);
        h4 {
            color: inherit;
            font-weight: 700;
            @include fluid-font(20px);
        }
        .btn {
            background-color: $orange;
            font-family: $oswald;
            @include letter-spacing(50);
            font-weight: 600;
            border-radius: 5px;
            &:hover {
                background-color: $blue;
            }
        }
    }
}


/***************************
******  BLOG Related  ******
***************************/
.news {
    padding: 0;
    .col {
        padding-top: 2em;
        padding-bottom: 2em;
    }
    .aside {
        background-color: rgba($green, $alpha: .07);
        flex: 0 0 326px;
        &--title {
            @include fluid-font(58px);
        }
    }
    @include bup(md){
        .aside {
            padding-top: 7em;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
    @include bup(md){
        .aside.large-gap {
            padding-top: 17em;
            padding-left: 6%;
        }
    }
}
article {
    padding: 12px;
    margin-bottom: 52px;
    position: relative;
    @include bdown(md) {
        margin-bottom: 25px;
    }
    
    .article-header {
        color: $green;
        @include transition;
        @include fluid-font(30px);
        @include letter-spacing(100);
        font-family: $montserrat;
        font-weight: normal;
        text-align: left;
        margin-bottom: 1em;
        a {
            color: $green;
            &:hover {
                color: $blue;
            }
        }
    }
    .article-inner {
        .date {
            span {
                font-family: $montserrat;
                color: $blue;
                font-weight: 500;
                &:nth-child(1){
                    @include fluid-font(28px);
                }
                &:nth-child(2){
                    @include fluid-font(14px);
                }
            }
        }
        .excerpt, .article-text {
            @include fluid-font(16px);
            font-family: $poppins;
            margin-top: 1.5em;
            max-width: 83%;
            line-height: 30px;
            @include bdown(md) {
                max-width: none;
                margin-top: 2em;
            }
        }
        .btn-container {
            margin-top: 1em;
            .read-more {
                // font-family: $poppins;
                @include fluid-font(21px);
                color: $blue;
                &:hover {
                    color: $blue;
                }
            }
        }
        .learn-more {
            font-family: $poppins;
        }
        .featured-media-container {
            &.video {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                iframe {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }

    }
    .link-more {
        font-family: $poppins;
    }
    &.article-item {
        .article-inner {
            border-bottom: 1px solid #707070;
            padding: 39px 0;
            .soc-share-container {
                float: right;
                @extend %share-style3;
            }
            .ssc-title {
                font-style: italic;
                color: #798889 !important;
            }
            @include bdown(md) {
                padding: 25px 3% 17px;
            }
        }
    }
    &.article-single {
        border: none;
        margin-bottom: 0;
        padding-top: 2.5em;
        .article-inner {
            max-width: 1100px;
            position: relative;
            .article-text {
                font-family: $montserrat;
                @include fluid-font(19px);
            }
            .date{
                padding-bottom: 1em;
                span {
                    color: $blue;
                    @include letter-spacing(100);
                }
            }
            @include bup(md) {
                padding-left: 6.5%;
                padding-right: 0;
            }
            @include block-center;
            h2.single-article-header {
                line-height: 1.2;
                text-align: left;
                margin-bottom: .2em;
                color: $blue;
            }
            .article-text {
                max-width: none;
                @include font-count(13, 16);
                p {
                    @include font-count(13, 16);
                }
            }
            .soc-share-wrapper {
                padding-bottom: 2em;
                border-bottom: 2px solid #5F5F5F;
            }
            .soc-share-container {
                padding-bottom: .5em;
                float: right;
                @extend %share-style3;
            }
        }
        .backlink-container {
            margin-bottom: 2em;
            margin-top: 2em;
            a {
                @include fluid-font(14px);
                @include letter-spacing(200);
                font-weight: 600;
                color: #A2A0A0;
                text-transform: uppercase;
                display: inline-flex;
                align-items: center;
                // font-family: $poppins;
                &:before {
                    color: $blue;
                }
                &:hover {
                    color: $blue;
                }
            }
        }
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
        @include bup(nm){
            margin-left: -5%;
        }
    }
}
.pagination-container {
    text-align: center;
}
.pagination {
    display: inline-flex;
    align-items: center;
    padding-bottom: 20px;
    @include bdown(md) {
        display: flex;
        justify-content: space-between; 
    }
    .pagination-list {
        flex-grow: 1;
        display: flex;
        justify-content: space-around;
        li {
            display: inline-block;
            margin-right: 7px;
            a {
                color: #363636;
                background-color: #E4F4F7;
                padding: 20px;
                text-align: center;
                min-width: 55px;
                padding: 18px 30px;
                font-weight: 300;
                @include font-count(12,24);
                &:hover, &.active-pag {
                    background-color: $blue;
                    color: #fff;
                }
            }
        }
        @include bdown(md) {
            li {
                margin: 0 1px;
                a {
                    min-width: 0;
                    padding: 9px 15px;
                }
            }
        }
        @include bdown(xs) {
            li a {
                padding: 9px;
            }
        }
    }
    .pag-arrow {
        border-radius: 5px;
        color: $green;
        font-size: 2em;
        // border: 2px solid transparent;
        flex-shrink: 0;
        display: flex;
        align-content: center;
        // img {
        //     width: 1em;
        //     height: 1em;
        // }
        &:hover {
            color: $green;
        }
        &:first-of-type {
            margin-right: 20px;
        }
        &:last-of-type {
            margin-left: 20px;
        }
        @include bdown(md) {
            &:first-of-type {
                margin-right: 7px;
            }
            &:last-of-type {
                margin-left: 7px;
            }
        }
        @include bdown(xs) {
            min-width: 30px;
            padding: 5px;
            &:first-of-type {
                margin-right: 5px;
            }
            &:last-of-type {
                margin-left: 5px;
            }
        }
    }
    &.single-pagination {
        display: flex;
        .pagination-list {
            justify-content: space-between;
        }
    }
}
.featured-media-container.video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.media-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.img-cover {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}
.featured-media-container.video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.media-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.img-cover {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}
.soc-share-container {
    padding-top: .5em;
    padding-right: 2%;
    @include bdown(md) {
        max-width: 190px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 25px;
        float: none;
        padding-right: 0;
    }
    .ssc-title {
        @include fluid-font(19px);
        color: $blue;
        margin-bottom: 5px;
    }
    ul {
        display: flex;
        justify-content: space-between;
        li {
            a {
                color: $blue;
                @include transition;
                @include fluid-font(20px);
                &:hover {
                    color: $blue;
                }
            }
        }
    }
}
.search-item {
    background-color: rgba($color: $green, $alpha: .14);
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    h2 {
        @include fluid-font(25px);
        color: $blue;
        font-family: $open-sans;
    }
    .btn {
        background-color: $green;
        font-family: $montserrat;
        @include letter-spacing(120);
        margin-top: 2rem;
    }
}
.flyout {
    position: relative;
    &:hover {
        .flyout-inner {
            display: block;
        }
    }

    &-inner {
        display: none;
        position: absolute;
        bottom: calc(100% + 25px);
        left: 30%;
        transform: translateX(-50%);
        color: #6C6C6C;
        background-color: #ECF8FD;
        border: 1px solid #B2CED9;
        font-family: $poppins;
        font-size: 10px;
        font-weight: 500;
        width: 193px;
        padding: .5rem;
        letter-spacing: normal;
        white-space: normal !important;
        &:before,
        &:after {
            position: absolute;
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 7.5px 0 7.5px;
        }
        &:before {
            border-color: #ecf8fd transparent transparent transparent;
            top: 100%;
            right: 20%;
            z-index: 10;
        }
        &:after {
            border-color: #B2CED9 transparent transparent transparent;
            top: calc(100% + 1px);
            right: 20%;
        }
    }

}