footer {
    color: #fff;
    h4 {
        font-family: $open-sans;
        text-align: left;
        color: $green;
        @include font-count(20, 30);
        margin-top: 0;
        margin-bottom: .4em;
        @include bup(md) {
            line-height: 40px;
        }
    }
    a {
        color: #e7e7e7;
        &:hover, &.active {
            color: $green;
        }
    }
    .footer-top {
        border-top: .68rem solid $green;
        background-color: #2f2f2f;
        .subheader {
            color: $blue;
            font-family: raleway;
            font-weight: 700;
            @include fluid-font(15px);
            margin-bottom: .5rem;
            display: block;
        }
        & > .container {
            padding-top: 2rem;
            padding-bottom: 2rem;
            position: relative;
            .footer-social-links {
                display: flex;
                @extend %share-style2;
            }
            & > .row {
                flex-wrap: wrap;
                & > .col {
                    // border-left: 3px solid rgba($color: $violet, $alpha: .19);
                    &:nth-child(1){
                        flex: 0 0 25%;
                    }
                    // &:nth-child(2) {
                    //     flex: 1 0 60%;
                    // }
                    &:nth-child(3) {
                        flex: 0 0 25%;
                    }
                    .footer-about {
                        font-size: 15px;
                        font-family: $open-sans;
                        color: #fff;
                        @include rlh(26px);
                    }
                    .footer-contacts {
                        font-family: $montserrat;
                        @include rlh(26px);
                        .icon {
                            position: relative;
                            padding-left: 2rem;
                            line-height: inherit;
                            span {
                                display: block;
                            }
                            &:before {
                                position: absolute;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                color: $orange;
                            }
                        }
                        & + a {
                            display: block;
                        }
                    }
                    .footer-quick-links {
                        text-transform: uppercase;
                        font-weight: 600;
                        @include fluid-font(16px);
                        display: inline-block;
                        text-align: left;
                        margin-bottom: 2rem;
                        &-header {
                            color: $green;
                            @include fluid-font(25px);
                            font-family: $montserrat;
                            margin-bottom: 1rem;
                        }
                        li {
                            margin-bottom: .6em;
                        }
                    }
                }
            }
        }
    }
    .footer-bottom {
        background-color: $blue;
        color: #fff;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        .col{
            &:first-of-type{
                @include fluid-font(17px);
                font-family: $montserrat;
            }
            &:last-of-type{
                flex-grow: 2;
                font-size: 13px;
                font-style: italic;
            }
        }
    }
    @include bup(md){
        .footer-top {
            .footer-contacts .icon {
                margin-bottom: 2rem;
            }
            form {
                margin-top: -12rem;
            }
        }
    }
    @include bdown(md) {
        .footer-top .container {
            .row {
                display: flex;
                .col {
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        flex-basis: 100%;
                    }
                    .footer-social-links {
                        padding-left: 0;
                        justify-content: center;
                    }
                }
                form {
                    .row {
                        display: block;
                    }
                }
            }
            .logo-container, .footer-about {
                text-align: center;
                margin-bottom: 1rem;
            }
        }
    }
}