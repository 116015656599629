@import "preloader/src/loaders.scss";

.preloaderOverlay {
  position: absolute;
  background: rgba(0,0,0, 0);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // .preloader {
  //   position: absolute !important;
  //   top: 50%;
  //   left: 50%;
  //   margin: -$loader-size*2 0 0 (-$loader-size/2);
  //   @include loader08($color: $blue);
  // }
  .preloader {
    position: absolute !important;
    top: 50%;
    left: 50%;
    width: 6rem;
    height: 6rem;
    margin-top: -3rem;
    margin-left: -3rem;
    background-image: url(../../images/chass-symb-logo.svg);
    background-repeat: no-repeat;
    background-size: cover;
    animation: loader-animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}
@keyframes loader-animation {
  from {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg) scale(.8);
    opacity: .8;
  }
  to {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
}