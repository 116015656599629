%title-block {
    color: #fff;
    .title-1 {
        @include fluid-font(30px);
        font-weight: 600;
        display: flex;
        margin-bottom: .5rem;
        @include letter-spacing(100);
    }
    .title-2 {
        @include fluid-font(64px);
        font-family: $oswald;
        line-height: 1;
        margin-bottom: .3em;
    }
    .title-3 {
        @include font-count(40, 77);
        display: inline-flex;
        align-items: center;
        color: $violet;
        margin-bottom: .3em;
        .sm {
            font-size: 16px;
        }
    }
    p {
        @include fluid-font(20px);
    }
}
%share-style1 {
    display: flex;
    padding-top: 0;
    padding-right: 0;
    align-items: center;
    a {
        font-size: 1.3em;
        height: 1.3em;
        width: 1.3em;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1.2rem;
        color: $green;
        &:hover {
            color: $blue;
        }
    }
}
%share-style2 {
    .icon {
        width: 2rem;
        height: 2rem;
        font-size: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        border: solid 2px;
        border-radius: 50%;
        &:before {
            font-size: .7em;
        }
        &:hover {
            color: #fff;
        }
        &-facebook {
            color: #3B5998;
            border-color: #3B5998;
            &:hover {
                background-color: #3B5998;
            }
        }
        &-pinterest {
            color: #CB2027;
            border-color: #CB2027;
            &:hover {
                background-color: #CB2027;
            }
        }
        &-youtube {
            color: #FF0000;
            border-color: #FF0000;
            &:hover {
                background-color: #FF0000;
            }
        }
        &-twitter {
            color: #7ED0E2;
            border-color: #7ED0E2;
            &:hover {
                background-color: #7ED0E2;
            }
        }
        &-linkedin {
            color: #486AAE;
            border-color: #486AAE;
            &:hover {
                background-color: #486AAE;
            }
        }
        &-instagram {
            color: #506897;
            border-color: #506897;
            &:hover {
                background-color: #506897;
            }
        }
    }
}
%share-style3 {
    display: flex;
    padding-top: 0;
    padding-right: 0;
    align-items: center;
    .ssc-title {
        color: $base;
        margin-bottom: 0;
    }
    ul {
        a {
            font-size: 1em;
            height: 1.5em;
            width: 1.5em;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }
        .icon-facebook {
            background-color: #3B5998;
        }
        .icon-twitter {
            background-color: #7ED0E2;
        }
        .icon-linkedin {
            background-color: #486AAE;
        }
        .icon-envelope-o {
            background-color: #6F7173;
        }
    }
}

%letter-form {
    .decorated {
        position: relative;
        &:after {
            width: 174px;
        }
    }
    h3 {
        @include letter-spacing(30);
        @include fluid-font(45px);
    }
    input, select, textarea {
        background-color: transparent;
        border: none;
        font-family: $raleway;
        @include fluid-font(16px);
        @include letter-spacing(200);
        width: 100%;
    }
    select, input {
        border-bottom: 3px solid #F0F2F4;
        line-height: 2rem;
        min-height: 2rem;
    }
    input {
        padding: 0;
    }
    select {
        -webkit-appearance: none;
        border-bottom-color: transparent;
    }
    textarea {
        background-image: linear-gradient(180deg,#fff,#fff calc(100% - 3px),#F0F2F4 calc(100% - 3px), #F0F2F4);
        background-size: 100% 3em;
        line-height: 2.5rem;
    }
    .select-wrapper {
        position: relative;
        border-bottom: 1px solid #F0F2F4;
        &:before {
            position: absolute;
            right: .2em;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            pointer-events: none;
        }
    }
}

%ulticks {
    li {
        list-style-type: none;
        position: relative;
        padding-left: 1em;
        &:before {
            content: "\e90c";
            font-family: $icomoon;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $blue;
            display: inline-block;
        }
    }
}
%ularrows {
    li {
        list-style-type: none;
        position: relative;
        padding-left: 1em;
        &:before {
            content: "\e91b";
            font-family: $icomoon;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
        }
    }
}
%phone-block {
    color: $orange;
    span {
        font-family: $oswald;
        @include fluid-font(25px);
        font-weight: 600;
    }
    // font-weight: 900;
    &:before {
        margin-right: .5rem;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        @include transition;
        background-color: $violet;
        color: #FAF6EC;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    &:hover {
        color: $blue;
        &:before {
            background-color: $blue;
        }
    }
}
%default-form {
    text-align: center;
    position: relative;
    input, select, textarea {
        @include font-count(16, 24);
        color: #505050;
    }
    .btn-container {
        .btn {
            padding: 1em 3em;
        }
    }
    .row {
        padding-top: 0px;
        padding-bottom: 0px;
        .col {
            margin-bottom: 1em;
            input, textarea {
                width: 100%;
                border: none;
            }
            input {
                height: 2.63em;
                padding: 0 1.5em;
            }
            .select-wrapper {
                height: 2.63rem;
                position: relative;
                @include font-count(16, 24);
                &:before {
                    position: absolute;
                    right: .2em;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 3em;
                    z-index: 10;
                    pointer-events: none;
                }
                &:after {
                    position: absolute;
                    display: block;
                    content: "";
                    right: 0;
                    top: 0;
                    width: 1.6em;
                    height: 100%;
                    background-color: #89D0EA;
                    font-size: 2em;
                    z-index: 5;
                    pointer-events: none;
                }
            }
            select {
                height: 2.63rem;
                padding: 0 1.5em;
                width: 100%;
                border-radius: 0;
                border: none;
                -webkit-appearance: none;
            }
            textarea {
                padding: 1.5em;
                // margin-bottom: 1em;
            }
            &.fg-2 {
                flex-grow: 2;
            }
            &.fg-3 {
                flex-grow: 3;
            }
            &.fg-4 {
                flex-grow: 4;
            }
            &.file-upload-container {
                text-align: right;
                &.has-danger {
                    border: 1px solid #f00;
                    mark {
                        color: #f00;
                    }
                }
            }
            label {
                text-align: left;
                color: $blue;
                @include fluid-font(23px);
                display: block;
                padding-bottom: .2em;
                &.file_upload {
                    display: inline-flex;
                    align-items: flex-start;
                    input[type=file]{
                        position: absolute;
                        visibility: hidden;
                        opacity: 0;
                        display: none;
                    }
                    mark {
                        background-color: transparent;
                        color: #fff;
                        opacity: 1;
                        margin-left: .5em;
                        @include transition;
                    }
                    .btn {
                        cursor: pointer;
                        &.empty {
                            background-color: transparent;
                            color: #CBCBCB;
                            border: 1px solid #CBCBCB;
                        }
                        &:not(.empty):hover {
                            & + mark {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
            @include bdown(md) {
                margin-bottom: 15px;
            }
            p {
                text-align: left;
                margin-bottom: 0.5em;
                &.question {
                    @include font-count(24, 33);
                }
                &.c-info {
                    @include font-count(16, 24);
                    @include letter-spacing(100);
                }
            }
        }
    }
    .total-container {
        border-top: 1px solid $blue;
        color: $blue;
        text-align: left;
        padding-top: .5em;
        margin-top: 2em;
        margin-bottom: 2em;
        span {
            color: inherit;
            @include font-count(20, 33);
        }
        .total {
            text-transform: uppercase;
        }
    }
    .checkbox input {
        display: none;
    }
    .checkbox label {
        position: relative;
        padding-left: 4em;
        line-height: 3em;
        cursor: pointer;
        &:before {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 3em;
            height: 3em;
            background-color: #fff;
        }
        &:after {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            padding-left: .4em;
            content: "";
            @include font-count(30, 48);
            color: $blue;
        }
    }
    
    .checkbox input:checked + label {
        &:after {
            content: "X";
        }
    }
    .captcha-inside {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        input {
            flex-basis: 50%;
        }
        @include bdown(md) {
            justify-content: flex-start;
            input {
                flex-basis: auto;
            }
        }
    }
}
%block-shadow-1 {
    position: relative;
    &:after {
        content: "";
        background-image: url(../../images/block-shadow.png);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -2rem;
        height: 2rem;
        pointer-events: none;
    }
}
%block-shadow-2 {
    position: relative;
    &:after {
        content: "";
        background-image: url(../../images/block-shadow.png);
        background-size: 100% 3.2rem;
        background-repeat: no-repeat;
        background-position: center top;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -3.2rem;
        height: 3.2rem;
        pointer-events: none;
    }
}
%block-shadow-3 {
    position: relative;
    &:after {
        content: "";
        background-image: url(../../images/block-shadow.png);
        background-size: 100% 3.2rem;
        background-repeat: no-repeat;
        background-position: center top;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0rem;
        height: 3.2rem;
        pointer-events: none;
    }
}
%double-shadow {
    position: relative;
    &:after {
        content: "";
        background-image: url(../../images/double-shadow.png);
        background-size: 100% 3.2rem;
        background-repeat: no-repeat;
        background-position: center top;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1.4rem;
        height: 3.2rem;
        z-index: -5;
    }
}


