@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700|Poppins:400,500,700|Raleway:600,700|Oswald:300,400|Open+Sans:400,700|Zilla+Slab:400,700&display=swap');
@import "icomoon";

$montserrat: 'Montserrat', sans-serif;
$open-sans: 'Open Sans', sans-serif;
$oswald: 'Oswald', sans-serif;
$raleway: 'Raleway', sans-serif;
$zilla: 'Zilla Slab', serif;
$oswald: 'Oswald', sans-serif;
$icomoon: "icomoon";
$poppins: 'Poppins', sans-serif;
