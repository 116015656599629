section.cf {
    position: relative;
    background-position: right 2rem;
    background-repeat: no-repeat;
    &--right {
        background-position: left 2rem;
        form {
            margin-left: auto;
        }
    }
    &:after {
        content: "";
        background-image: linear-gradient(rgba($color: #F6F7F5, $alpha: 1 ), rgba($color: #EDEFEC, $alpha: 1 ), rgba($color: #DFE1DE, $alpha: 0 ));
        position: absolute;
        left: 0;
        top: 0;
        height: 30%;
        width: 100%;
    }
    &.clear {
        background-position: right top;
        &:after {
            content: none;
        }
    }
    form {
        position: relative;
        z-index: 5;
    }
}
.pay-form-top {
    text-align: center;
    &--title {
        font-size: 1.16rem;
        font-family: $open-sans;
        color: #525252;
        margin-bottom: 1.5rem;
    }
    &--inner {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        & > span {
            padding: 0 1rem;
            font-family: $raleway;
            font-size: .73rem;
            @include letter-spacing(200);
        }
    }
}

form {
    input, select, textarea {
        &.has-danger {
            border: 1px solid #f00 !important;
        }
    }
    .thankyou-url {
        display: none;
    }
}
.footer-top {
    form.hsf {
        ::placeholder {
            color: $blue;
        }
        @include bup(md) {
            padding: 2rem 4rem;
            max-width: none;
            margin-left: auto;
            margin-right: auto;
        }
        input, select, textarea {
            color: $blue;
        }
        .btn {
            font-family: $raleway;
            font-size: 12px;
            font-weight: 700;
            @include letter-spacing(150);
            padding: 1rem;
        }
    }
}
form.hsf {
    @extend %letter-form;
    background-color: #fff;
    // color: #4a4a4a;
    // text-align: center;
    ::placeholder {
        color: $violet;
        text-transform: uppercase;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        transition: background-color 5000s ease-in-out 0s;
        // -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    // input::-internal-autofill-selected {
    //     background-color: transparent !important;
    // }


    input, select, textarea {
        color: $violet;
        // text-transform: uppercase;
    }
    h3 {
        font-family: $open-sans;
        font-weight: 700;
        color: $blue;
        @include fluid-font(46px);
    }
    .col {
        margin-bottom: 1rem;
    }
    .bottom-info {
        color: #707070;
        font-size: 14px;
    }
    &#pay-bill {
        max-width: none;
        ::placeholder {
            color: #525252;
        }
        input, select, textarea {
            color: #525252;
            font-family: $open-sans;
            font-size: 1.15rem;
            letter-spacing: normal;
            text-transform: none;
            &.has-danger {
                border: none !important;
                border-bottom: 3px solid #f00 !important;
            }
        }
        label {
            color: $violet;
            font-family: $open-sans;
            font-size: .73rem;
            font-weight: 700;
            @include letter-spacing(200);
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
        label + input + label {
            color: #ACACAC;
            font-family: $raleway;
            font-size: .74rem;
        }
        button.btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 1rem;
            padding: .75rem 2rem;
            img {
                margin-right: 1rem;
            }
        }
        .bottom-info {
            font-size: .79rem;
            font-style: italic;
            font-weight: 500;
            color: #818181;
        }
        @include bup(nm) {
            .col.with-overflow {
                max-width: 25%;
            }
        }
        @include bup(md) {
            max-width: none;
            label.ws-nowrap {
                white-space: nowrap;
            }
        }
        @include bbetween(md, nm){
            .row {
                flex-wrap: wrap;
            }
            .col,
            .col.with-overflow {
                flex: 0 0 50%;
            }
            .col.fg-1 {
                flex-grow: 1;
            }
        }
    }
    &#form_pay {
        max-width: none;
        label {
            color: $violet;
            font-family: $open-sans;
            font-size: .73rem;
            font-weight: 700;
            @include letter-spacing(200);
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
        input, select, textarea {
            border: none;
        }
        .input-container,input, select, textarea {
            color: $violet;
            font-family: $oswald;
            @include fluid-font(46px);
            @include letter-spacing(120);
            text-transform: none;
            &.has-danger {
                border: none !important;
                border-bottom: 3px solid #f00 !important;
            }
        }
        .input-container {
            display: flex;
            align-items: center;
            border-bottom: 3px solid #f0f2f4;
            padding-right: 1em;
        }
        .limiter {
            width: 250px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2rem;
        }
        button.btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 1rem;
            padding: .75rem 2rem;
            margin: 0 1rem;
            flex: 0 1 49%;
            justify-content: space-around;
            img {
                margin-right: .5rem;
            }
            div {
                text-align: center;
                span {
                    display: block;
                    text-transform: none;
                    &.smaller {
                        @include fluid-font(15px);
                        @include letter-spacing(150);
                    }
                }
            }
        }
        .bottom-buttons {
            display: flex;
            justify-content: center;
        }
        @include bdown(md){
            .bottom-buttons {
                display: block;
                button.btn {
                    margin: 0 0 1rem;
                    width: 100%;
                    img {
                        max-width: 20%;
                    }
                }
            }
        }
    }
    @include bup(md) {
        padding: 2rem;
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    @include bdown(md) {
        padding: 1rem;
    }
}
form#login-form{
    @extend %letter-form;
    .row {
        margin-bottom: 1rem;
    }
    .col {
        align-items: center;
        &.vcenter {
            display: inline-flex;
            margin-bottom: 5px;
        }
    }
    label {
        font-family: $montserrat;
        @include fluid-font(18px);
        font-weight: 700;
        @include letter-spacing(100);
    }
    input, select, textarea {
        background-color: #fff;
        padding: 0 1rem;
        font-weight: 700;
        @include fluid-font(18px);
        border-color: transparent;
        font-family: $montserrat;
    }
    input {
        line-height: 45px;
    }
    .btn {
        background-color: $blue;
        &:hover {
            background-color: $green;
        }
    }

}
form.default-form {
    @extend %default-form;
    h2 {
        @include fluid-font(45px);
    }
    h3 {
        @include fluid-font(27px);
    }
}
form.order-literature {
    @extend %default-form;
    input, select, textarea {
        background-color: #EBF5E2;
    }
    .quantity-block {
        max-width: 1040px;
        margin-left: auto;
        margin-right: auto;
        .col {
            &:first-of-type{
                display: flex;
                align-items: center;
                border-top: solid #A2E5F9 1px;
                border-bottom: solid #A2E5F9 1px;
            }
            &:last-of-type{
                flex: 0 0 30%;
            }
        }

        label {
            font-family: $montserrat;
            font-weight: 700;
            @include fluid-font(32px);
        }
        @include bdown (md){
            .row {
                margin-bottom: 40px;
            }
            label {
                padding: 1rem !important;
            }
        }
    }
}
.pencil {
    .col {
        position: relative;
        &:after {
            position: absolute;
            top: 1.75rem;
            transform: translateY(-50%);
            content: "\e92d";
            right: 2rem;
            font-family: $icomoon;
            color: $violet;
        }
    }

}
