#slider-items {
    .title-1 {
        &.animated {
            animation-duration: 2s;
        }
    }
    .title-2 {
        &.animated {
            animation-delay: .5s;
            animation-duration: 2s;
        }
    }
    .btn {
        &.animated {
            animation-duration: 3s;
            animation-delay: 1.5s;
        }
    }
}
#slider-items .invisible,
#middle-slider .invisible {
    .title-1, .title-2, .btn {
        visibility: hidden !important;
        opacity: 0;
    }
}
#slider-items .slick-slide {
    &.away-bg {
        animation-name: sliderBackgroundreverse;
        animation-duration: 1s;
        animation-direction: forwards;
    }
}
#middle-slider .slick-slide {
    &.away-bg {
        animation-name: sliderBackgroundreverse;
        animation-duration: 1s;
        animation-direction: forwards;
    }
}
#middle-slider {
    .title-2 {
        &.animated {
            animation-delay: .5s;
        }
    }
}
.main-slider-loading {
    overflow: hidden;
}
.main-slider-loading .preloaderOverlay .preloader {
    top: 70%;
}
.slider {
    position: relative;
    min-height: 180px;
    width: 100%;
    @include bdown(md) {
        min-height: 0px;
    }
    
}
.slick-slide {
    &:focus {
        outline: none;
    }
}
.slick-track {
    display: flex;
    align-items: center;
}
.slider-instance {
    &.init {
        opacity: 0;
        height: 80px;
    }
}
.slick-dots {
    display: block;
    li {
        display: inline-block;
        padding: 0 7px;
    }
    .slick-dot {
        width: 14px;
        height: 14px;
        background-color: $blue;
        border-radius: 50%;
        position: relative;
        // &:before {
        //     content: "";
        //     display: block;
        //     width: 22px;
        //     height: 22px;
        //     border-radius: 50%;
        //     top: 50%;
        //     left: 50%;
        //     border: 2px solid transparent;
        //     position: absolute;
        //     transform: translate(-50%, -50%);
            
        // }
        &:hover {
            background-color: $orange;
        }
    }
    .slick-active {
        .slick-dot {
            background-color: $orange;
        }
    }
    // .slick-active {
    //     .slick-dot {
    //         background-color: $violet;
    //         &:before {
    //             border-color: $violet;
    //         }
    //     }
    // }
}
.main-slider {
    @include transition;
    overflow: hidden;
    &#middle-slider {
        .description {
            .slider-title-block {
                .title-1 {
                    background-color: $violet;
                    padding: .3rem .5rem;
                }
                .title-2 {
                    background-color: rgba($color: #1C1C1C, $alpha: .8);
                    padding: .3rem .5rem;
                }
            }
        }
        // .invisible {
        //     .title-1, .title-2, .btn {
        //         visibility: hidden !important;
        //         opacity: 0;
        //     }
        // }
    }
    &.slider-instance.init {
        height: 400px;
    }
    .slick-arrow {
        position: absolute;
        color: $gray;
        z-index: 100;
        top: 50%;
        transform: translateY(-50%);
        font-family: $icomoon;
        @include fluid-font(50px);
        @include transition;
        background-color: rgba($color: $blue, $alpha: .32);
        border-radius: 50%;
        width: 0;
        height: 0;
        padding: 3rem;
        &:hover {
            background-color: rgba($color: $blue, $alpha: .7);
        }
        &:before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .slick-prev {
        left: -3rem;
        &:before {
            right: 1.5rem;
        }
    }
    .slick-next {
        right: -3rem;
        &:before {
            left: 1.5rem;
        }
    }
    &#slider-items {
        .slider-item {
            &.slick-active {
                animation-name: sliderBackground;
                animation-duration: 2s;
                animation-direction: forwards;
            }
        }
    }
    .slider-item {
        position: relative;
        background-size: cover !important;
        background-position: center top;
        background-repeat: no-repeat;
        min-height: 30vh;
        max-height: 100vh;
        &.no-grad {
            &:after {
                content: none !important;
            }
        }
        &.position-left {
            &:after {
                display: block;
                content: "";
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                background-image: linear-gradient(to right, rgba($color: #00526F, $alpha: .82), rgba($color: #007BA6, $alpha: .82) 25%, transparent 50%);
            }
        }
        &.position-right {
            &:after {
                display: block;
                content: "";
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                background-image: linear-gradient(to right, rgba($color: #00526F, $alpha: .82), rgba($color: #007BA6, $alpha: .82) 25%, transparent 50%);
                @include bup(md){
                    background-image: linear-gradient(to left, rgba($color: #00526F, $alpha: .82), rgba($color: #007BA6, $alpha: .82) 25%, transparent 50%);
                }
            }
            .description .inner {
                @include bup(md){
                    right: 0;
                }
                @include bbetween(md, lg){
                    right: 15%;
                    left: auto;
                }
            }
        }
    }
    .description {
        position: absolute;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        min-width: 33%;
        height: 100%;
        max-width: 1400px;
        width: 100%;
        z-index: 15;
        .inner {
            position: absolute;
            // width: 47%;
            // top: 160px;
            // height: calc(100% - 160px);
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .slider-title-block {
            @extend %title-block;
            // padding-top: 1rem;
            line-height: 1;
            // background-color: rgba($color: $green, $alpha: .81);
            color: #fff;
        }
    }
    
    .slick-dots {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translateX(-50%);
    }
    @include bup(md) {
        &.no-min-height {
            .slider-item {
                min-height: 0;
            }
        }
    }
    @include bup(sm) {
        .description {
            .slider-title-block {
                p {
                    max-width: 610px;
                }
            }
        }
    }
    @media screen and (max-width: 1400px){
        .description {
            .inner {
                left: 15%;
            }
        }
    }
    @include bdown(md) {
        .slider-item {
            min-height: 40vh;
            &.position-left, &.position-right {
                &:after {
                    content: none;
                }
            }
        }
        .description {
            .inner {
                width: auto;
                left: 10%;
                right: 10%;
                text-shadow: 0 1px 1px #505050;
            }
        }
        &.no-min-height {
            .slider-item {
                min-height: 200px;
            }
        }
    }
    @include bdown(sm) {
        .description {
            .inner {
                padding: 1em 0;
            }
            .slider-buttons-block {
                margin-top: 0;
                .slider-button {
                    margin: .3em 0;
                    padding: .5em 1em;
                }
            }
        }
    }
    @include bdown(xs) {
        .description {
            .inner {
                left: 5%;
                right: 5%;
            }
            .slider-title-block {
                .title-2 {
                    padding-top: .15em;
                    padding-bottom: .15em;
                }
                p {
                    margin-bottom: 1em;
                }
            }
        }
        .slick-arrow {
            display: none !important;
        }
    }
}
.our-partners.slider {
    @include bup(md){
        padding-bottom: 9rem;
        margin-bottom: -9rem;
        position: relative;
        z-index: 5;
        &:after {
            position: absolute;
            content: "";
            left: 0;
            width: 100%;
            top: calc(100% - 9rem);
            bottom: 0;
            background-image: linear-gradient(to bottom, #fff, transparent);
        }
    }
}
.partners-slider {
    padding-left: 5%;
    padding-right: 5%;
    .slick-slide {
        // height: 70px;
        padding-left: 7px;
        padding-right: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
            height: 100%;
            max-height: 100%;
            max-width: 100%;
        }
        img {
            margin-left: auto;
            margin-right: auto;
        }
        .bottom-text {
            text-align: center;
            @include font-count(12, 15);
            font-weight: 600;
            margin-top: 1em;
        }
    }
    .slick-prev.icon,
    .slick-next.icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        color: $orange;
        &:before {
            @include transition;
            display: inline-block;
            text-align: center;
            @include font-count(20, 32);
        }
        &:hover {
            color: $blue;
        }
    }
    .slick-prev {
        left: 3%;
    }
    .slick-next {
        right: 3%;
    }
    @include bdown(xs) {
        padding-left: 12%;
        padding-right: 12%;
    }
}
.testimonials-container {
    padding-top: 0;
    padding-bottom: 0;
    .container.fw {
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
}
.testimonials-slider {
    padding: 1rem 2rem;
    .slick-track, .slick-list {
        display: flex !important;
        // justify-content: center;
    }
    .slick-track {
        justify-content: center;
    }
    .slick-slide {
        // display: flex;
        height: 100%;
    }
    .inner {
        position: relative;
        height: 100%;
    }
    .testimonial {
        padding: 0 9%;
        position: relative;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $blue;
        @include bup(md){
            top: 0;
            transition: 1.5s ease-in-out;
        }
        @include fluid-font(15px);
        p {
            margin-bottom: 0;
        }
        .ts {
            &-text {
                margin-bottom: 1em;
                position: relative;
                font-family: $montserrat;
                margin-bottom: 0;
                @include rlh(40px);
                @include fluid-font(30px);
                border-top: 4px solid $green;
                border-bottom: 4px solid $green;
                padding-top: 1rem;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                p {
                    line-height: inherit;
                }
                &:before {
                    content: "\e903";
                    transform: rotate(-180deg);
                    display: block;
                    color: $green;
                    font-family: $icomoon;
                    position: absolute;
                    left: -5rem;
                    top: 0;
                    font-size: 3rem;
                }
                &:after {
                    content: "\e903";
                    display: block;
                    color: $green;
                    font-family: $icomoon;
                    position: absolute;
                    right: -5rem;
                    bottom: 0;
                    font-size: 3rem;
                }
            }
            &-author {
                @include fluid-font(29px);
                font-weight: 700;
                font-family: $montserrat;
                @include letter-spacing(100);
                color: $green;
            }
            &-position {
                font-family: $open-sans;
                @include fluid-font(18px);
                color: #000;
            }
            &-author-position {
                padding-right: 3rem;
                display: inline-flex;
                flex-direction: column;
                align-self: flex-end;
                align-items: flex-start;
            }
        }
    }
    .slick-prev.icon,
    .slick-next.icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        color: #B4B4B4;
        z-index: 100;
        &:before {
            @include transition;
            display: inline-block;
            text-align: center;
            @include font-count(20, 50);
        }
        &:hover {
            color: $blue;
        }
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
    .slick-dots {
        text-align: center;
    }
    @include bup(md) {
        // padding-top: 16px;
        // padding-bottom: 16px;
        // padding-left: 5%;
        // padding-right: 5%;
        
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
    @include bdown(md) {
        padding: 25px 1rem;
        .testimonial {
            padding: 0 2.5rem;
        }
        // .inner {
        //     padding-left: 5%;
        //     padding-right: 5%;
        //     margin-left: 5%;
        //     margin-right: 5%;
        // }
        .testimonial .ts-text {
            &:before {
                left: -2rem;
                font-size: 2rem;
            }
            &:after {
                right: -2rem;
                font-size: 2rem;
            }
        }
    }
}
.packages {
    &--inner {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        padding-top: 8rem;
        img {
            position: absolute;
            bottom: calc(100% - 7rem);
            max-height: 7rem;
            left: 1.2rem;
        }
    }
    &--slider {
        @include bup(md){
            padding-left: 7%;
            padding-right: 7%;
        }
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    &--info {
        border-left: 1px solid $blue;
        padding-left: 1rem;
        h5 {
            color: $blue;
            @include fluid-font(25px);
        }
        p {
            @include fluid-font(20px);
        }
        a {
            font-family: $oswald;
            @include fluid-font(15px);
            color: $orange;
            font-weight: 600;
            &:after {
                content: "\e90a";
                font-family: $icomoon;
                padding-left: .5rem;
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 2rem;
        color: $blue;
        &:hover {
            color: $violet;
        }
        &.slick-prev {
            left: 1rem;
        }
        &.slick-next {
            right: 1rem;
        }
        @include bdown(xs){
            &.slick-prev {
                left: .5rem;
            }
            &.slick-next {
                right: .5rem;
            }
        }
    }

}
.learn-how {
    &--inner {
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        img {
           
        }
    }

    &--slider {
        @include bup(md){
            padding-left: 7%;
            padding-right: 7%;
        }
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    &--info {
        padding-left: 1rem;
        padding-top: 1rem;
        h5 {
            color: $blue;
            font-family: $zilla;
            @include fluid-font(37px);
            font-weight: 700;
            color: $dark;
            line-height: 1;
        }
        p {
            @include fluid-font(20px);
            text-transform: uppercase;
            color: $blue;
            font-family: $oswald;
            font-weight: 600;
            margin-bottom: 0;
        }
        a {
            font-family: $oswald;
            @include fluid-font(15px);
            color: $orange;
            font-weight: 600;
            &:after {
                content: "\e90a";
                font-family: $icomoon;
                padding-left: .5rem;
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 2rem;
        color: $blue;
        &:hover {
            color: $violet;
        }
        &.slick-prev {
            left: 1rem;
        }
        &.slick-next {
            right: 1rem;
        }
        @include bdown(xs){
            &.slick-prev {
                left: .5rem;
            }
            &.slick-next {
                right: .5rem;
            }
        }
    }

}
@keyframes sliderBackground {
    from {
        background-position: -400px 0px;
    }
    to {
        background-position: center 0;
    }
}
@keyframes sliderBackgroundreverse {
    from {
        background-position: center 0;
    }
    to {
        background-position: -200px 0px;
    }
}