@import "typography";
html {
    font-size: 19px;
    font-family: $montserrat;
    @include bdown(md){
        font-size: 14px;
    }
}
a[href],
button {
    &:not(.disabled):not(:disabled) {
        cursor: pointer;
    }
    &.disabled,
    &:disabled {
        cursor: default;
        pointer-events: none;
    }
    transition: all .3s ease-in-out;
}
body {
    line-height: 1.25;
    font-size: 1rem;
    color: $base;
}
.gray {
    color: $gray;
}
.blue {
    color: $blue;
}
.white {
    color: #fff;
}
.green {
    color: $green;
}
.orange {
    color: $orange;
}
.orange-bg {
    background-color: $orange;
}
.blue-bg {
    background-color: $blue;
}
.green-bg {
    background-color: $green;
}
.lightest-blue-bg {
    background-color: rgba($color: $blue, $alpha: .06);
}
.light-green-bg {
    background-color: rgba($color: $green, $alpha: .14);
}
.lightest-green-bg {
    background-color: rgba($color: $green, $alpha: .06);
}
.dark-blue-bg {
    background-color: #1D235B;
}
.beige-bg {
    background-color: #f7f7f7;
}
.brown-bg {
    background-color: $green;
}
.dark-bg {
    background-color: #444;
}
.violet-bg {
    background-color: $violet;
}
.no-bg {
    background-image: none !important;
}
.underline {
    text-decoration: underline;
}
.all-caps {
    text-transform: uppercase;
}
.pr {
    position: relative;
}
.bold {
    font-weight: 700;
}
.no-pt {
    padding-top: 0;
}
.no-pb {
    padding-bottom: 0;
}
.no-p {
    padding-top: 0;
    padding-bottom: 0;
}
.block-shadow {
    @extend %block-shadow-2;
    &--top{
        @extend %block-shadow-3;
    }
}
.to-be-animated {
    visibility: hidden;
}

p {
    margin-bottom: 1em;
}
h1, h2, h3, h4, h5, h6 {
    margin-bottom: .4em;
    font-family: $oswald;
    color: $dark;
}
h2, .h2-style {
    @include fluid-font(57px);
    line-height: 1;
    position: relative;
    color: $green;
}
h3 {
    @include fluid-font(30px);
}
h4 {
    @include fluid-font(25px);
    font-family: $montserrat;
    @include letter-spacing(200);
    color: #5F5F5F;
}
h5 {
    @include fluid-font(20px);
}
h6 {
    @include fluid-font(19px);
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
@include bup(md){
    .responsive {
        &-text-right {
            text-align: right;
        }
        &-text-center {
            text-align: left;
        }
    }
}

@include bup(md){
    .r-text-left {
        text-align: left;
    }
    .r-text-center {
        text-align: center;
    }
    .r-text-right {
        text-align: right;
    }
}
.flex {
    &--am {
        align-items: center;
    }
    &--auto {
        flex-basis: auto !important;
    }
}
p {
    line-height: 26px;
    &.subtitle {
        margin-bottom: .5em;
        text-align: center;
        @include letter-spacing(200);
        @include fluid-font(26px);
        color: $blue;
    }
    @include bdown(md) {
        line-height: 1.5;
    }
    // &.intro {
    //     font-family: $montserrat;
    //     margin-bottom: .5em;
    //     @include font-count(17, 22);
    //     @include letter-spacing(30);
    // }
}
.pretitle {
    margin-bottom: 0.5rem;
    font-family: $montserrat;
    text-transform: uppercase;
    @include fluid-font(15px);
    @include letter-spacing(100);
    font-weight: 700;
    color: $violet;
}
.decorated {
    position: relative;
    &:after {
        content: url(#{$images}/icons/decoration.svg);
        display: block;
    }
    &--line {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        position: relative;
        &:after {
            width: 50px;
            height: 3px;
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $green;
        }
        &--orange:after {
            background-color: $orange;
        }
        &--blue:after {
            background-color: $violet;
        }
        &--white:after {
            background-color: #fff;
        }
    }
}
.double-arrow {
    display: inline-flex;
    align-items: center;
    color: $blue;
    font-family: $oswald;
    font-size: 15px;
    font-weight: 600;
    @include letter-spacing(50);

    &:hover{
        color: $violet;
    }
    &:after {
        content: "\e90a";
        padding-left: .5rem;
        font-family: $icomoon;
        display: inline-block;
    }
}


a[name^="eztoc"] {
    display: none !important;
}
// .text-center {
//     ul {
//         text-align: left;
//     }
// }
.btn {
    color: #fff;
    background-color: $violet;
    @include fluid-font(18px);
    padding: .75em 1em;
    @include transition;
    font-family: $oswald;
    line-height: normal;
    border: none;
    text-transform: uppercase;
    @include letter-spacing(120);
    display: inline-flex;
    align-items: center;
    &--rounded {
        border-radius: 2em;
    }
    &--x2 {
        &:before {
            font-size: 1.5em;
        }
    }
    &--alt {
        background-color: $blue;
        &:hover {
            background-color: $green;
        }
    }
    &--small {
        padding: .5em 1em;
    }
    &--static {
        &:before {
            padding-right: .5rem;
        }
    }
    &--block {
        display: block;
        text-align: center;
    }
    &--arrowed {
        padding-right: 4em;
        position: relative;
        &:after {
            position: absolute;
            content: "\e90a";
            display: block;
            right: 2em;
            top: 50%;
            transform: translateY(-50%);
            font-family: icomoon;
        }
    }
    &--icon-right {
        padding-right: 4em;
        position: relative;
        &:before {
            position: absolute;
            display: block;
            right: 2em;
            top: 50%;
            transform: translateY(-50%);
            font-family: $icomoon;
        }
    }
    &:hover {
        background-color: $blue;
    }
    &.opaque {
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;
        &:hover {
            color: $blue;
            border-color: $blue;
        }
    }
    
    // font-size: 18px;
}
.flex {
    &--aic {
        align-items: center;
    }
}


section {
    ul:not([class]) {
        margin-bottom: 20px;
        margin-left: 0;
        display: inline-flex;
        flex-direction: column;
        li {
            list-style-type: none;
            position: relative;
            padding-left: 1.5em;
            &:before {
                content: "\e90c";
                font-family: $icomoon;
                position: absolute;
                left: 0;
                top: 0;
                // top: 50%;
                // transform: translateY(-50%);
                color: $green;
                display: inline-block;
            }
            @include bup(md){
                line-height: 26px;
            }
        }
    }
    ol:not([class]) {
        margin-bottom: 20px;
        margin-left: 15px;
        display: inline-flex;
        flex-direction: column;
    }
    .text-block.no-dot {
        ul {
            li {
                &:before {
                    content: none;
                }
            }
        }
    }
}
section a:not([class]) {
    color: $blue;
    &:hover {
        color: $violet;
    }
}

